import React from 'react'
import styles from './Footer.module.css'
import { FaAngleRight } from 'react-icons/fa'
import UserPolicy from '../User Policy/UserPolicy'
import { Link } from 'react-router-dom'
import Contact from '../ContactUs/Contact'

function Footer() {
  return (
    <>
    <Contact/>
    <div className={styles.Footer} id='contact-us'>
        {/* <div className={styles.upperFooter}>
            <h1>What are you waiting for?</h1>
            <p>Let's get you home!</p>
            <div className={styles.connectFooter}> 
                <span>Connect</span>
                <span className={styles.FaAngleRight}> <FaAngleRight />
                </span>
            </div>
        </div>
        <div className={styles.footerElementContainer}>
            <div>
                <p><b>Call:</b> <a style={{textDecoration:'none',color:'gray'}} href='tel: 1800-103-1123'>1800-103-1123</a></p>
            </div>
            <p><b>Email:</b><a style={{textDecoration:'none',color:'gray'}}  href='mailto: abc@dummy.com'> abc@dummy.com</a></p>
            
        </div> */}
            
        <div className={styles.copyrightandPoliciesContainer}>
            <span style={{color:'gray',textDecoration:'none',fontSize:'small'}}>
            Copyright © 2014-2024 Suresh Hostel/PG: All rights reserved
            </span> 
            <div>
                <Link></Link>
            <span> <Link  style={{color:'gray',textDecoration:'none',fontSize:'small'}} to='/user-policy'>User Policy &nbsp;</Link> </span>
            <span> <Link  style={{color:'gray',textDecoration:'none',fontSize:'small'}} to='/terms'> Privacy & Terms Condition</Link> </span>
            </div>
        </div>
        <div className={styles.horizontalLine}></div>
        <div>
            <a href='https://matrixinfotechsolution.com/' target='blank' className={styles.companyname}> <b>Developed and Managed by Matrix Infotech Solution</b></a>
        </div>

    </div>
    </>
  )
}

export default Footer