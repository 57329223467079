import React, { useEffect } from 'react';
import styles from '../Home/Home.module.css';
function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.aboutUs} id='about-us'>
        <div className={styles.aboutUsPara}>
          <h1>About Us</h1>
          <p>We, Suresh Hostel/PG, located C-54, Ground floor, near Bengali Sweets, South Extension I, Block E, New Delhi, Delhi 110049, offer a pocket friendly stay to each patron and 
            welcome them with complete warmth and hospitality. We offer an array of all essential services that 
            are rendered by the hostel for a hassle free stay at no extra costs. For accommodation, the property 
            offers spacious, airy and well-lit rooms, featuring sophisticated and welcoming ambience with the warmth 
            and comfort of home. We take utmost care of the safety and security of individuals and their belongings 
            staying in our hostel.
          </p>
        </div>
        <img src='/images/aboutus.jpg' alt='suresh-pg aboutus alt'/>
      </div>
  )
}

export default AboutUs