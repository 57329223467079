import React from "react";
import styles from "./Review.module.css";
// import { useSelector } from "react-redux";
// import { reviews } from "../../../ReduxToolkit/Reducers/HomeReducer";
import Carousel from "better-react-carousel";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleLeft, faStar } from "@fortawesome/free-solid-svg-icons";
import { testimonialData } from "../../Data/TestimonialData";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
export default function Review() {
  const monthNames = [
    "Jan", "Feb", "March", "April", "May", "June",
    "July", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: 'inline-block',
        height: isActive ? '8px' : '5px',
        width: isActive ? '8px' : '5px',
        background: '#1890ff'
      }}
    ></span>
  )
  function ArrowLeft(){
    return(
        <div className={styles.backButton}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </div>
    )
  }
  const responsiveDesign = [
    {
      breakpoint: 1500,
      cols: 1,
      rows: 1,
      gap: 10,
      loop: true,
      autoplay: 1000
    },
    {
      breakpoint: 1100,
      cols: 1,
      rows: 1,
      gap: 10,
      loop: true,
      autoplay: 1000
    },
    
  ]
  
  //   const { review } = props;
  return (
    <div className={styles.Carousel}>
      <Carousel cols={1} rows={1} gap={0} loop scrollSnap autoplay={5000} showDots responsiveLayout={responsiveDesign} > 
        {testimonialData.map((review, i) => {
          return (
            <Carousel.Item key={i} >
              <div className={styles.container}>
                <div className={styles.Review}>
                    <div className={styles.imageNameStarsContainer}>
                        <div className={styles.imageNameContainer}>
                            <img src={review.imageUrl} alt="suresh pg review profile picture"/>
                            <span>{review.name}</span>
                        </div>
                        <div className={styles.starContainer}>
                            <FaStar color="orange"  />
                            <FaStar color= {review.rating>=2? 'orange':'grey'}  />
                            <FaStar color= {review.rating>=3? 'orange':'grey'}  />
                            <FaStar color= {review.rating>=4? 'orange':'grey'}  />
                            <FaStar color= {review.rating>=5? 'orange':'grey'}  />
                        </div>
                    </div>
                    <div className={styles.comment}> 
                        {review.review}
                    </div>
                    <div className={styles.date}>
                        {`${review.date.getDate()} ${monthNames[review.date.getMonth()]} ${review.date.getFullYear()}` }
                    </div>

                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}
