import { RouterProvider } from 'react-router-dom'
import './App.css'
import { HelmetProvider } from 'react-helmet-async';
import router from './Layout'


function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router}></RouterProvider>
    </HelmetProvider> 
  )
}

export default App
