import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';

function Contact1() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <Helmet>
    <title>Contact Us - Suresh Hostel/PG</title>
    
    <meta name="description" content="Get in touch with Suresh Hostel/PG at C-54, Ground floor, near Bengali Sweets, South Extension I, Block E, New Delhi, Delhi 110049. Email us at info@sureshhostel.com or call us at +91-8882141008, +91-9599153466. Office hours: 9 AM to 8 PM (all 7 days)." />
    
    <meta name="keywords" content="contact Suresh Hostel, Suresh PG, hostel in South Extension, Delhi hostel contact, accommodation, student housing, PG in Delhi" />
    
    <meta property="og:title" content="Contact Us - Suresh Hostel/PG" />
    <meta property="og:description" content="Reach out to Suresh Hostel/PG for inquiries. Visit us at C-54, Ground floor, near Bengali Sweets, South Extension I, Block E, New Delhi, Delhi 110049. Email: info@sureshhostel.com, Phone: +91-8882141008, +91-9599153466. Office hours: 9 AM to 8 PM (all 7 days)." />
    <meta property="og:image" content="https://sureshhostel.com/images/Logo.png" />
    <meta property="og:url" content="https://sureshhostel.com/contact" />
    <meta property="og:type" content="website" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Contact Us - Suresh Hostel/PG" />
    <meta name="twitter:description" content="Contact Suresh Hostel/PG at C-54, Ground floor, near Bengali Sweets, South Extension I, Block E, New Delhi, Delhi 110049. Email: info@sureshhostel.com, Phone: +91-8882141008, +91-9599153466. Office hours: 9 AM to 8 PM (all 7 days)." />
    <meta name="twitter:image" content="https://sureshhostel.com/images/Logo.png" />

    <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>

    <h1 style={{textAlign:'center',margin:'35px'}}><span style={{color:' #05abc9'}}>Contact</span> us</h1>
    </>
    
  )
}

export default Contact1