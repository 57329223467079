import React, { useEffect } from "react";
import styles from './Terms.module.css';
import { Helmet } from "react-helmet-async";

export default function Terms() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className={styles.Privacy}>
                <Helmet>
                    <title>Terms of Service - Suresh Hostel/PG</title>
                </Helmet>
                <div className={styles.imgcontainer}>
                    <h1>TERMS OF SERVICE</h1>
                </div>
                <div className={styles.policypara}>
                    <h2>1. Introduction</h2>
                    <p>Welcome to Suresh Hostel. These terms and conditions outline the rules and regulations for staying at Suresh Hostel.</p>

                    <h2>2. Payment and Security Deposit</h2>
                    <ul>
                        <li><strong>Advance Payment:</strong> A one-month advance payment is required as a security deposit at the time of booking. This deposit is mandatory to secure your accommodation at Suresh Hostel.</li>
                        <li><strong>Security Deposit Adjustment:</strong> The security deposit will be adjusted against the final month’s rent, provided all conditions are met.</li>
                        <li><strong>No Refund Policy:</strong> Suresh Hostel maintains a strict no refund policy. Once paid, the security deposit and any other payments made will not be refunded under any circumstances.</li>
                    </ul>

                    <h2>3. Booking and Cancellation</h2>
                    <ul>
                        <li><strong>Booking Confirmation:</strong> Your booking will only be confirmed once the advance payment has been received.</li>
                        <li><strong>Cancellation:</strong> If you decide to cancel your booking, please note that no refund will be provided for the advance payment made.</li>
                    </ul>

                    <h2>4. Rules and Regulations</h2>
                    <ul>
                        <li><strong>Compliance:</strong> Residents must comply with all rules and regulations set forth by Suresh Hostel. Any violation may result in termination of your stay without refund.</li>
                        <li><strong>Damage and Liability:</strong> Residents are responsible for any damage caused to hostel property. The cost of repairs will be deducted from the security deposit.</li>
                    </ul>

                    <h2>5. Check-In and Check-Out</h2>
                    <ul>
                        <li><strong>Check-In:</strong> Check-in time is from [insert time here]. Please inform the management if you will be arriving late.</li>
                        <li><strong>Check-Out:</strong> Check-out time is [insert time here]. Any delay in check-out will incur additional charges.</li>
                    </ul>

                    <h2>6. Conduct</h2>
                    <ul>
                        <li><strong>Behavior:</strong> All residents are expected to maintain a respectful and peaceful environment. Any disruptive behavior may result in immediate expulsion without refund.</li>
                        <li><strong>Guests:</strong> No guests are allowed to stay overnight without prior permission from the management.</li>
                    </ul>

                    <h2>7. Utilities and Services</h2>
                    <ul>
                        <li><strong>Utilities:</strong> Basic utilities (electricity, water) are included in the rent. Excessive use may incur additional charges.</li>
                        <li><strong>Services:</strong> Cleaning and maintenance services are provided regularly. Residents are expected to maintain cleanliness in their rooms and common areas.</li>
                    </ul>

                    <h2>8. Termination of Stay</h2>
                    <ul>
                        <li><strong>By the Resident:</strong> If you wish to terminate your stay before the agreed period, no refund will be provided for the remaining duration.</li>
                        <li><strong>By the Management:</strong> Suresh Hostel reserves the right to terminate the stay of any resident without refund for violation of rules, disruptive behavior, or non-payment of rent.</li>
                    </ul>

                    <h2>9. Amendments</h2>
                    <p>Suresh Hostel reserves the right to amend these terms and conditions at any time. Residents will be notified of any changes in advance.</p>

                    <p>By staying at Suresh Hostel, you agree to abide by these terms and conditions. We look forward to providing you with a comfortable and pleasant stay.</p>
                </div>
            </div>
        </>
    );
}
