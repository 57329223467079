import React, { useEffect } from 'react';
import styles from '../Home/Home.module.css';
import { galleryData } from '../../Data/galleryData';
function Gallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.homegallery} id='gallery'>

          <h1>Gallery</h1>
          <div className={styles.slidingDiv}>
            {galleryData.map((img,index) =>
              (
                <img key={index} src={img} alt='Suresh-pg-gallery-img'/>
              )
            )}
            
          </div>          
      </div>
  )
}

export default Gallery