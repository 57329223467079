import React, { useEffect, useState } from 'react'
import styles from './Service.module.css';
import { servicePointer } from '../../Data/servicePointers';
import { LiaHandPointer } from 'react-icons/lia';
import { serviceData } from '../../Data/servicesData';

// import { ServiceSlider } from '../Home/Home';
function Service() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Service} id='services'>
        
        <div className={styles.ServiceHeader}>
          <h1>Why Choose us</h1>
          <p>Experience Comfort, Embrace Community</p>
          <div className={styles.keyPointersAndImageContainer}>
            <div className={styles.keyPointerContainer}>
              {servicePointer.map((point,index) => 
              (<div key={index}> <LiaHandPointer style={{ transform: 'rotate(90deg)' }} /> &nbsp; {point}</div>)
              )}

            </div>
            <img className={styles.serviceBgImg} src='/images/serviceBg.jpg' alt='Suresh pg service image'/>
          </div>
          <h1>Our Services</h1>
          <p>Comfort and Convenience, Every Day</p>
          <div className={styles.sliderAndServicesContainer}>
            <div className={styles.servicesContainer}>
              <img  src='/images/serviceBg2.jpg' alt='Suresh pg service image'/>
            </div>
            <div className={styles.servicePointsContainer}>
                {serviceData.map((service,index) =>{
                  return(
                    <div className={styles.servicePoint} key={index}>
                      <p>{service.title}</p>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>

    </div>
  )
}

export default Service

