import React from 'react'
import Service from './Service'
import { Helmet } from 'react-helmet-async';

function ServicePage() {
  return (
    <>
    <Helmet>
    <title>Service - Suresh Hostel/PG</title>
    <meta name="keywords" content="Fully Furnished Rooms: Includes bed, study table, chair, wardrobe, etc, Shared or Private Rooms: Options for single, double, or dormitory-style rooms, Balcony / Terrace Access: Outdoor space for relaxation, Daily Housekeeping: Regular cleaning of rooms and common areas, Laundry Services: On-site laundry facilities or laundry services, Wi-Fi Connectivity: High-speed internet access throughout the property, 24/7 Security: Security personnel, CCTV surveillance, and secure access, Common Kitchen: Shared kitchen facilities for self-cooking, First Aid Facilities: Basic medical supplies and first aid kits, Computer Stations: Shared computers or workstations for resident use, Just 100m walking distance from metro station." />
    
    <meta name="description" content="Suresh Hostel/PG offers fully furnished rooms, shared or private room options, balcony access, daily housekeeping, laundry services, high-speed Wi-Fi, 24/7 security, and more. Just 100m from the metro station." />
    <meta name="author" content="Suresh Hostel/PG" />
    
    
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    
    <meta property="og:title" content="Service - Suresh Hostel/PG" />
    <meta property="og:description" content="Explore the best-in-class hostel/PG services at Suresh Hostel/PG with fully furnished rooms, shared or private room options, and more. Conveniently located near the metro station." />
    <meta property="og:image" content="https://sureshhostel.com/images/Logo.png" />
    <meta property="og:url" content="https://sureshhostel.com/service" />
    <meta property="og:type" content="website" />

    
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Service - Suresh Hostel/PG" />
    <meta name="twitter:description" content="Fully furnished rooms, high-speed Wi-Fi, daily housekeeping, and more at Suresh Hostel/PG. Just 100m from the metro station." />
    <meta name="twitter:image" content="https://sureshhostel.com/images/Logo.png" />

    </Helmet>

    <Service/>
    </>
  )
}

export default ServicePage;