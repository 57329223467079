export const testimonialData =[
    {
        imageUrl:'/images/profilePicture1.jpg',
        name:'Udgar',
        review:'I lived here for 1year It was nice experience .if you are searching a good food and good pg in budget then go for It.',
        date: new Date(2024, 1, 5),
        rating:5,
    },
    {
        imageUrl:'/images/profilePicture.jpg',
        name:'Mayank Kushwaha',
        review:'Food is very good Mess pg and facilities is very good.  Services and maintenance is superb',
        date: new Date(2024, 3, 12),
        rating:4,
    },
    {
        imageUrl:'/images/profilePictureFemale.jpg',
        name:'Anjali',
        review:'Really good pg friendly owner worth living..lived here for 1year. Food and provide RO water all are good and hygienic..',
        date: new Date(2023, 5, 25),
        rating:5,

    },
    {
        imageUrl:'/images/profilePicture2.jpg',
        name:'Rahul Singh',
        review:'This hostel is a great place to stay for students. The location is convenient, with easy access to public transportation and nearby markets. The rooms are comfortable, and the Wi-Fi is reliable. The warden is very approachable and helps with any issues. Overall, a great place to stay while studying in the city.',
        date: new Date(2023, 6, 18),
        rating:5,
    },
    {
        imageUrl:'/images/profilePictureFemale.jpg',
        name:'Priya Patel',
        review:'I had a fantastic time at this hostel. The staff is friendly and always ready to assist. The common areas are well-maintained and provide a great space for relaxing and socializing. The hostel also organizes fun activities and events, which helped me make new friends. A great choice for anyone looking for a comfortable and friendly environment.',
        date: new Date(2023, 1, 12),
        rating:4,
    },
    {
        imageUrl:'/images/profilePictureFemale.jpg',
        name:'Neha Verma',
        review:"This PG offers a great balance between comfort and affordability. The rooms are neat and have all the necessary amenities. The management is responsive and ensures that any concerns are addressed promptly. The community here is friendly, and I've made some great friends. I would definitely recommend this place to others",
        date: new Date(2023, 3, 20),
        rating:5,
    },
    {
        imageUrl:'/images/profilePicture3.jpg',
        name:'Pranjul Pathak',
        review:'Was really happy with their service while I was staying there. Would recommend others as well.',
        date: new Date(2024, 7, 28),
        rating:5,
    },
];
