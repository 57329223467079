export const roomsData =[
    {
        title:'Single Sharing Rooms',
        images:[
            '/images/Rooms/SingleBed/b1.webp',
            '/images/Rooms/SingleBed/b2.jpg',
        ]
    },
    {
        title:'Double Sharing Rooms',
        images:[
            '/images/Rooms/DoubleBed/b1.jpg',
            '/images/Rooms/DoubleBed/b2.jpg',
            '/images/Rooms/DoubleBed/b3.jpg',
        ]
    },
    {
        title:'Three Sharing Rooms',
        images:[
            '/images/Rooms/ThreeBed/b1.jpg',
            '/images/Rooms/ThreeBed/b2.jpg',
            '/images/Rooms/ThreeBed/b3.png',
        ]
    },
    {
        title:'Four Sharing Rooms',
        images:[
            '/images/Rooms/FourBed/b1.jpg',
            '/images/Rooms/FourBed/b2.jpg',
            '/images/Rooms/FourBed/b3.jpg',
        ]
    },
];
