import React, { useEffect, useState } from 'react'
import styles from './Home.module.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {  MdCancel, MdNavigateNext } from 'react-icons/md';
import { roomsData } from '../../Data/roomsData';
import { GrFormPrevious } from 'react-icons/gr';
import Review from '../Review/Review';
import Service from '../Service/Service';
import Header from '../Header/Header';
import { girlsRoomData } from '../../Data/girlsRoomData';
import { Helmet } from 'react-helmet-async';
import AboutUs from '../About-us/AboutUs';
import Gallery from '../Gallery/Gallery';
import Contact from '../ContactUs/Contact';

function Home() {
  

  return (
    <>
    <Helmet>
    <title>Suresh PG in South Extension Part 1 | South Delhi | Affordable & Secure Accommodation</title>
    
    <meta name="description" content="PG in South Extension 1: Rent fully-furnished Ladies/Gents Paying Guests in Delhi for students and working professionals with ✓homely meals ✓housekeeping ✓wifi ✓CCTV ✓ AC ✓single/multiple occupancy. Check our 3D Virtual Tours or Visit Now! Find the best PG hostel in South Extension 1, Green Park, Kotla Mubarakpur, and Lajpat Nagar. Affordable and secure accommodations for students of Lady Shri Ram College, St. Stephen's College, Hindu College, Jesus and Mary College, Gargi College, Sri Venkateswara College, Kamala Nehru College, and Atma Ram Sanatan Dharma College. Book your stay now!" />
    
    <meta name="keywords" content="PG in South Extension 1, PG in South Extension 2, PG in Kotla Mubarakpur, PG in Green Park, PG in Lajpat Nagar, PG near Nehru Stadium, PG near Kamla Nehru College, PG near Lady Shri Ram College, PG near St. Stephen's College, PG near Hindu College, PG near Jesus and Mary College, PG near Gargi College, PG near Sri Venkateswara College, PG near Kamala Nehru College, PG near Atma Ram Sanatan Dharma College, student accommodation in South Delhi, PG in South Delhi, girls' PG in South Delhi, secure PG in South Extension, affordable PG in South Extension, boys' PG in South Extension 1, boys' PG in South Extension 2, boys' PG in Kotla Mubarakpur, boys' PG in Green Park, boys' PG in Lajpat Nagar, boys' PG near Nehru Stadium, boys' PG near South Delhi colleges, girls' PG in South Extension 1, girls' PG in South Extension 2, girls' PG in Kotla Mubarakpur, girls' PG in Green Park, girls' PG in Lajpat Nagar" />

    <meta name="author" content="Suresh Hostel/PG" />
    <meta name="name" content="Suresh Hostel/PG" />
    <meta name="address" content="C-54, Ground floor, near Bengali Sweets, South Extension I, Block E, New Delhi, Delhi 110049" />
    <meta name="telephone" content="+91-8882141008, 9599153466" />
    <meta name="email" content="info@sureshhostel.com" />
    <meta name="owner" content="Suresh Hostel/PG" />
    <meta name="geo.position" content="28.573457; 77.221469" />
    <meta name="geo.placename" content="Suresh PG, South Extension 1, South Delhi, Delhi, India" />
    <meta name="geo.region" content="IN-DL" />

    <meta property="og:title" content="PG in South Extension 1 | Best Boys & Girls Paying Guest/PG for Students & working Professionals in Delhi" />
    <meta property="og:description" content="PG in South Extension 1: Rent fully-furnished Ladies/Gents Paying Guests in Delhi for students and working professionals with ✓homely meals ✓housekeeping ✓wifi ✓CCTV ✓ AC ✓single/multiple occupancy. Check our 3D Virtual Tours or Visit Now! Best PG hostel near South Extension 1, Green Park, Kotla Mubarakpur, and Lajpat Nagar. Affordable and secure accommodations for students of Lady Shri Ram College, St. Stephen's College, Hindu College, Jesus and Mary College, Gargi College, Sri Venkateswara College, Kamala Nehru College, and Atma Ram Sanatan Dharma College. Book your stay now!" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.sureshhostel.com" />
    <meta property="og:image" content="https://www.sureshhostel.com/public/images/logo.png" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="PG Hostel in South Delhi | Affordable & Secure Accommodation" />
    <meta name="twitter:description" content="Find the best PG hostel in South Extension 1, Green Park, Kotla Mubarakpur, and Lajpat Nagar. Affordable and secure accommodations for students of Lady Shri Ram College, St. Stephen's College, Hindu College, Jesus and Mary College, Gargi College, Sri Venkateswara College, Kamala Nehru College, and Atma Ram Sanatan Dharma College. Book your stay now!" />
    <meta name="twitter:image" content="https://www.sureshhostel.com/public/images/logo.png" />

    <script type="application/ld+json">
      {`{
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        "name": "Suresh Hostel/PG",
        "image": "https://www.sureshhostel.com/public/images/logo.png",
        "@id": "https://www.sureshhostel.com",
        "url": "https://www.sureshhostel.com",
        "telephone": "+91-8882141008",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "C-54, Ground floor, near Bengali Sweets, South Extension I, Block E, New Delhi, Delhi 110049",
          "addressLocality": "South Extension 1",
          "addressRegion": "Delhi",
          "postalCode": "110049",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "28.573457",
          "longitude": "77.221469"
        },
        "sameAs": [],
        "priceRange": "₹500 - ₹1000",
        "openingHours": "Mo-Su 09:00-20:00",
        "description": "Find the best PG hostel in South Extension 1, Green Park, Kotla Mubarakpur, and Lajpat Nagar. Affordable and secure accommodations for students of Lady Shri Ram College, St. Stephen's College, Hindu College, Jesus and Mary College, Gargi College, Sri Venkateswara College, Kamala Nehru College, and Atma Ram Sanatan Dharma College. Book your stay now!",
        "founder": {
          "@type": "Person",
          "name": "Suresh"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.5",
          "reviewCount": "89"
        },
        "review": [
          {
            "@type": "Review",
            "author": "Student A",
            "datePublished": "2024-06-30",
            "description": "Great place to stay. Very secure and close to all the major colleges.",
            "name": "Excellent PG Hostel",
            "reviewRating": {
              "@type": "Rating",
              "bestRating": "5",
              "ratingValue": "5",
              "worstRating": "1"
            }
          }
        ]
      }`}
    </script>
</Helmet>

    <Header/>
    
    <div className={styles.Home} id='home'>
    
      <div className={styles.HomeHeader}>
        <div style={{width:'460px',marginRight:'15%'}}>
        <h1 className={styles.homeHeaderHeading}> Comfortable and Affordable Hostels Rooms</h1>
        <div className={styles.homeHeaderEnquireNow} > 
          <a href='tel: +918882141008'>Enquire Now</a>
        </div>

        </div>
      </div>
      <Rooms/>
      <Service/>
      <AboutUs/>
      <Gallery/>


    </div>
    <div className={styles.Review} >
      <div className={styles.featureHeadingContainer}>
          <p>What People Say?</p>
          <h1> <span style={{color:' #05abc9'}}>Reviews</span> By Our Clients</h1>
      </div> 
      <div className={styles.ReviewBackground}>

      </div>
    </div>
    <Review/>
    {/* <Contact/> */}
    
    </>
  )
}

export default Home

// export function ServiceSlider(props) {
//   const{index} = props;
//   const[counter,setCounter] = useState(0);
//   const handlePrev =(e) =>{
//     e.preventDefault();
//     if(counter===0) return;
//     setCounter(c => c-1);

//   }
//   const handleNext =(e) =>{
//     e.preventDefault();
//     if(counter===serviceData.length-1) return;
//     setCounter(c => c+1);
//   }

//   return(
//     < >
//       {serviceData.map((service, i) =>{
//         return(
//           <div style={{transform:`translateY(${(i-counter)*100}%)`}} key={i} className={styles.service}>
//             <img  src={service.imageUrl}/>
//             <h2>{service.title} </h2>
//             <p>{service.desc}</p>
//           </div>
//         )
//       })}
//       {counter>0?      
//         <button className={styles.ServicePrevButton} onClick={handlePrev}>
//           <FaAngleUp/>
//         </button>
//         : undefined
//       }
//       {counter< serviceData.length-1? 
//         <button className={styles.ServiceNextButton} onClick={handleNext}>
//           <FaAngleDown />
//         </button>
//         : undefined
//       }
//     </>
//   )
// }

function Roomslider(props){
  const{index,popupIndex,boys} = props;

  const[counter,setCounter] = useState(0);
  // useEffect(() =>{
  //   if(popupIndex){
  //     document.body.style.overflow='hidden';
  //   }else{
  //     document.body.style.overflow='';
  //   }
  //   return () => {
  //     document.body.style.overflow = '';
  //   };
  // },[popupIndex])

  const handlePrev =(e) =>{
    e.preventDefault();
    console.log('prev clicked');
    if(counter===0) return;
    setCounter(c => c-1);

  }
  const handleNext =(e) =>{
    e.preventDefault();
    console.log('next clicked');

    if( boys && counter===roomsData[index].images.length-1) return;
    if(!boys && counter===girlsRoomData[index].images.length-1) return;
    setCounter(c => c+1);
  }

  // const handlePopupIndex = (index) =>{
  //   if(!popupIndex){
  //     console.log("index" + index);
  //     setPopupIndex(index);
  //   }
  // }
  // const handleCancelpopup =() =>{
  //   console.log("cancel popup");
  //   setPopupIndex(null);
  // }

  return(
    <>

    
    
    <div  className={ popupIndex===index?styles.popupSlider :styles.slider} >
      
      
      {boys?
        roomsData[index].images.map((image,i) =>{
        return <img  className={styles.sliderImage} style={{transform:`translateX(${(i-counter)*100}%)`}} key={i} src={image} alt='suresh-pg-boysroom-img'/>
        })
        :
        girlsRoomData[index].images.map((image,i) =>{
          return <img  className={styles.sliderImage} style={{transform:`translateX(${(i-counter)*100}%)`}} key={i} src={image} alt='suresh-pg-girlsroom-img'/>
          })  
      }
      {counter>0?      
        <button className={styles.prevButton} onClick={handlePrev}>
          <GrFormPrevious />
        </button>
        : undefined
      }
      {boys?
        counter< roomsData[index].images.length-1? 
          <button className={styles.nextButton} onClick={handleNext}>
            <MdNavigateNext />
          </button>
          : undefined
        :
        counter< girlsRoomData[index].images.length-1? 
          <button className={styles.nextButton} onClick={handleNext}>
            <MdNavigateNext />
          </button>
          : undefined
      }
    </div>

    
    </>
  )
}

export function Rooms(){
  const[popupIndex, setPopupIndex] = useState(null);
  const[boys,setBoys] = useState(true);
  const[girs,setGirls] = useState(false);
  


  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePopupIndex = (index) =>{
    console.log("index" + index);
    setPopupIndex(index);
  }
  const handleCancelpopup =() =>{
    setPopupIndex(null);
  }
  const handleSetBoys = () =>{
    setGirls(false);
    setBoys(true);
  }
  const handleSetgirls = () =>{
    setGirls(true);
    setBoys(false);
  }
  useEffect(() => {
    if (popupIndex!=null) {
      document.body.classList.add(styles.noScroll); // Disable scrolling
    } else {
      document.body.classList.remove(styles.noScroll); // Enable scrolling
    }
    return () => {
      document.body.classList.remove(styles.noScroll); // Cleanup
    };
  }, [popupIndex]);


  return(
    <>
    <div className={styles.homeRooms} id='rooms' >
        
        <h1>Rooms</h1>
        <div>
          <span onClick={handleSetBoys} className={`${styles.genderSelectbutton} ${boys?styles.genderSelectedButton:undefined}`}>Boys</span>
          <span onClick={handleSetgirls}  className={`${styles.genderSelectbutton} ${!boys?styles.genderSelectedButton:undefined}`}>Girls</span>
        </div>
        <div className={styles.roomContainer} >
        
          {boys?
            roomsData.map((rooms,index) => {
              return(
                <div className={popupIndex===index?styles.popuproom:styles.rooms} key={index} onClick={() =>handlePopupIndex(index)}>
                  <Roomslider boys={boys} index={index} popupIndex={popupIndex}/>
                  <p className={styles.roomstitle}>{rooms.title}</p>
                </div>
              )
            })
          :
          
          girlsRoomData.map((rooms,index) => {
            return(
              <div className={popupIndex===index?styles.popuproom:styles.rooms} key={index} onClick={() =>handlePopupIndex(index)}>
                <Roomslider boys={boys} index={index} popupIndex={popupIndex}/>
                <p className={styles.roomstitle}>{rooms.title}</p>
              </div>
            )
          })
          }

          {popupIndex!=null?
            <div className={styles.popupCancelButton} onClick={handleCancelpopup}>
            <MdCancel />
            </div>
            :
            undefined
          }
          {popupIndex!=null?
            <div className={styles.overlay}>

            </div>
            :
            undefined
          }


        </div>
      </div>
    </>
  )
}