import React from 'react'
import Header from './Components/Header/Header';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
// import Contact from './Components/ContactUs/Contact';
import UserPolicy from './Components/User Policy/UserPolicy';
import Terms from './Components/Terms/Terms';
import Contact1 from './Components/ContactUs/Contact1';
import RoomPage from './Components/Rooms/RoomPage';
import ServicePage from './Components/Service/ServicePage';
import AboutUsPage from './Components/About-us/AboutUsPage';
import GalleryPage from './Components/Gallery/GalleryPage';

const Layout = () => {
    return (
      <>
        <Header />
        <Outlet /> {/* This renders the child routes */}
        <Footer />
      </>
    );
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/pg-rooms-in-south-extension', element: <RoomPage /> },
        { path: '/service', element: <ServicePage /> },
        { path: '/about-us', element: <AboutUsPage /> },
        { path: '/gallery', element: <GalleryPage /> },
        { path: '/contact', element: <Contact1 /> },
        { path: '/user-policy', element: <UserPolicy /> },
        {path:'/terms', element: <Terms/>}
      ],
    },
  ]);
  
  export default router;
