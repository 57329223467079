import React, { useEffect, useState } from 'react'
import styles from './Header.module.css';
import { BsList } from 'react-icons/bs';
import { MdOutlineCancel } from 'react-icons/md';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet-async";
import { Link, NavLink, Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';


  function SideNavbar(props){
    const[close,setClose] = useState(false);
    const handleClose =() =>{
        setClose(true); 
    }
    useEffect(() =>{
        if(close){
            const timer = setTimeout(() =>{
                props.handleSideNavBar();
            },1500);
            
            return ()=> {
                clearTimeout(timer);
                setClose(false); 
            };


        }

    },[close])

    return(
        
        <div className={styles.navlist}>
            
            <div className={`${styles.firstSideDiv} ${close? styles.firstSideDivClose:undefined}`}>
                <div className={styles.sideNavElementContainer}>
                    
                </div>
                
                <div className={styles.sideNavElementContainer}>
                    <Link to='/' className={styles.sideNavElement} onClick={handleClose}> HOME </Link>
                    <Link to='/' style={{color:'red'}} className={styles.sideNavElement} onClick={handleClose}>HOME</Link>

                </div>
                <div className={styles.sideNavElementContainer}>
                    <Link to='/pg-rooms-in-south-extension' className={styles.sideNavElement} onClick={handleClose}> ROOMS </Link>
                    <Link to='/pg-rooms-in-south-extension' style={{color:'red'}} className={styles.sideNavElement} onClick={handleClose}>ROOMS</Link>
                </div>
                <div className={styles.sideNavElementContainer}>
                    <Link to='/service' className={styles.sideNavElement} onClick={handleClose}> SERVICES </Link>
                    <Link to='/service' style={{color:'red'}} className={styles.sideNavElement} onClick={handleClose}>SERVICES</Link>
                </div>
            </div>
            <div className={`${styles.secondSideDiv}  ${close? styles.secondSideDivClose:undefined}`} >
                <div className={styles.sideNavElementContainer}>
                    <Link to='/about-us' className={styles.sideNavElement} onClick={handleClose}> ABOUT US </Link>
                    <Link to='/about-us' style={{color:'red'}} className={styles.sideNavElement} onClick={handleClose}>ABOUT US</Link>
                </div>
                <div className={styles.sideNavElementContainer}>
                    <Link to='/gallery' className={styles.sideNavElement} onClick={handleClose}> GALLERY </Link>
                    <Link to='/gallery' style={{color:'red'}} className={styles.sideNavElement} onClick={handleClose}>GALLERY</Link>

                </div>
                
               
            </div>
            <div className={`${styles.thirdSideDiv} ${close? styles.thirdSideDivClose:undefined}`}>
                <div className={styles.sideNavElementContainer}>
                    <Link to='/contact' className={styles.sideNavElement} onClick={handleClose}> CONTACT US </Link>
                    <Link to='/contact' style={{color:'red'}} className={styles.sideNavElement} onClick={handleClose}>CONTACT US</Link>

                </div>
            
            </div>
            <div className={`${styles.fourthSideDiv} ${close? styles.fourthSideDivClose:undefined}`}>
                {/* <div className={styles.sideNavCancelButton} onClick={handleClose}>
                    <MdOutlineCancel />
                </div> */}
            </div>
            <div className={`${styles.fourthSideDiv} ${close? styles.fourthSideDivClose:undefined}`}>
                <div className={styles.sideNavCancelButton} onClick={handleClose}>
                    <MdOutlineCancel />
                </div>
            </div>

        </div>
    )
  }

  function Header() {
    const[hover,setHover] = useState(false);
    const[companyBox,setCompanyBox] = useState(false);
    const[searchBar,setSearchBar] = useState(false);
    const[sideNav,setSideNav] = useState(false);
    

    const handleMouseEnter =() =>{
        setHover(true);
        setCompanyBox(false);
    }
    const handleMouseLeave =() =>{
        setHover(false);
    }
    const handleClickCompany =() =>{
        setSearchBar(false);
        setCompanyBox(company => !company);
    }
    const handleSearchBar =() =>{
        setCompanyBox(false);
        setSearchBar(searchbar => !searchbar);
    }
    const handleSideNavBar =() =>{
        setCompanyBox(false);
        setSearchBar(false);
        setSideNav(prev => !prev);
    }

    return (
        <>
        <div className={styles.header}>
            <div className={styles.headerFirstDiv}>
                <img className= {styles.logo} src='/images/Logo.png' alt='suresh pg logo'/>
                
                <div className={styles.responsiveNavbar}>
                    <BsList style={{fontSize:'30px',cursor:'pointer'}} onClick={handleSideNavBar}/>
                </div>
                <h1 className={styles.pgTitle}>Suresh Hostel/PG</h1>
                
            </div>
            <div className={styles.ElementContainer}>
                <div className={styles.headerElement}>
                    <NavLink to='/' className={({ isActive }) => (isActive ? styles.ActiveNavlink : styles.notActiveNavlink)}>HOME</NavLink>
                </div>
                <div className={styles.headerElement}>
                    <NavLink to='/pg-rooms-in-south-extension' className={({ isActive }) => (isActive ? styles.ActiveNavlink : styles.notActiveNavlink)}>ROOMS</NavLink>
                </div>
                <div className={styles.headerElement}>
                    <NavLink to='/service' className={({ isActive }) => (isActive ? styles.ActiveNavlink : styles.notActiveNavlink)}>SERVICES</NavLink>
                </div>
                <div  className={`${styles.headerElement} ${styles.companyButton}`}>
                    <NavLink to='about-us' className={({ isActive }) => (isActive ? styles.ActiveNavlink : styles.notActiveNavlink)}>ABOUT US</NavLink>
                </div>
                <div className={`${styles.headerElement} ${styles.companyButton}`}>
                    <NavLink to='gallery' className={({ isActive }) => (isActive ? styles.ActiveNavlink : styles.notActiveNavlink)}>GALLERY</NavLink>
                </div>

                <div className={`${styles.headerElement} ${styles.companyButton}`}>
                    <NavLink to='contact' className={({ isActive }) => (isActive ? styles.ActiveNavlink : styles.notActiveNavlink)}>CONTACT US</NavLink>
                </div>
            </div>

            <div className={styles.enqueryContainer} > 
                <a href="tel:+918882141008"> <FontAwesomeIcon icon={faPhone} shake/> Call Now</a>
            </div>
            
            
            {sideNav? <SideNavbar handleSideNavBar={handleSideNavBar}/>:undefined}
        </div>
        <div className={styles.empty}></div> {/* empty div to cover margin of header */}

        </>        
    );
  }

export default Header