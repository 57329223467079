import React, { useEffect } from "react";
import styles from './UserPolicy.module.css';
import { Helmet } from "react-helmet-async";

export default function UserPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className={styles.Privacy}>
                <Helmet>
                    <title>User Policy - Suresh Hostel/PG</title>
                </Helmet>
                <div className={styles.imgcontainer}>
                    <h1>USER POLICY</h1>
                </div>
                <div className={styles.policypara}>
                    <h2>1. Introduction</h2>
                    <p>Welcome to Suresh Hostel. This User Policy outlines the guidelines and expectations for all residents to ensure a safe, respectful, and comfortable living environment.</p>

                    <h2>2. General Conduct</h2>
                    <ul>
                        <li><strong>Respect:</strong> All residents must treat fellow residents, staff, and visitors with respect and courtesy at all times.</li>
                        <li><strong>Noise Levels:</strong> Residents are expected to keep noise levels to a minimum, especially during quiet hours from [insert quiet hours here].</li>
                        <li><strong>Prohibited Items:</strong> The use of illegal substances, weapons, and any hazardous materials is strictly prohibited.</li>
                    </ul>

                    <h2>3. Use of Facilities</h2>
                    <ul>
                        <li><strong>Common Areas:</strong> Residents are responsible for keeping common areas clean and tidy. Personal belongings should not be left in these areas.</li>
                        <li><strong>Kitchen Use:</strong> Residents must clean up after themselves in the kitchen and label their food items.</li>
                        <li><strong>Laundry Facilities:</strong> Laundry facilities are available for resident use. Please follow posted guidelines and schedules.</li>
                    </ul>

                    <h2>4. Room Maintenance</h2>
                    <ul>
                        <li><strong>Room Cleanliness:</strong> Residents are responsible for maintaining the cleanliness of their rooms. Regular inspections may be conducted.</li>
                        <li><strong>Reporting Issues:</strong> Any maintenance issues or damages should be reported to the management promptly.</li>
                        <li><strong>Furniture:</strong> Hostel-provided furniture should not be moved or altered without permission.</li>
                    </ul>

                    <h2>5. Safety and Security</h2>
                    <ul>
                        <li><strong>Personal Safety:</strong> Residents should ensure their personal safety by locking their rooms and not sharing keys or access codes.</li>
                        <li><strong>Emergency Procedures:</strong> Familiarize yourself with emergency exits and procedures. Follow all safety drills and instructions from the staff.</li>
                        <li><strong>Guests:</strong> Guests are not allowed to stay overnight without prior permission from the management. Residents are responsible for the behavior of their guests.</li>
                    </ul>

                    <h2>6. Payment and Financial Obligations</h2>
                    <ul>
                        <li><strong>Timely Payment:</strong> Rent must be paid on time as per the agreed schedule. Late payments may incur additional fees.</li>
                        <li><strong>Security Deposit:</strong> A one-month security deposit is required. This will be adjusted against the final month’s rent, provided all conditions are met.</li>
                        <li><strong>No Refund Policy:</strong> Suresh Hostel maintains a strict no refund policy. Once paid, the security deposit and any other payments made will not be refunded under any circumstances.</li>
                    </ul>

                    <h2>7. Termination of Stay</h2>
                    <ul>
                        <li><strong>By the Resident:</strong> If you wish to terminate your stay before the agreed period, no refund will be provided for the remaining duration.</li>
                        <li><strong>By the Management:</strong> Suresh Hostel reserves the right to terminate the stay of any resident without refund for violation of rules, disruptive behavior, or non-payment of rent.</li>
                    </ul>

                    <h2>8. Amendments</h2>
                    <p>Suresh Hostel reserves the right to amend this User Policy at any time. Residents will be notified of any changes in advance.</p>

                    <p>By staying at Suresh Hostel, you agree to abide by this User Policy. We look forward to providing you with a comfortable and pleasant stay.</p>
                </div>
            </div>
        </>
    );
}
