

export const serviceData =[
    {
        imageUrl:'/images/Services/furnishedRoom.jpg',
        title:'Furnished Rooms',
        desc:'We provide fully furnished rooms with quality mattresses, tables closets and other required facilities.'
        
    },
    {
        imageUrl:'/images/Services/wifi.jpg',
        title:'WIFI',
        desc:'We provide wireless high speed internet so that you can stay connected with your friends and family.'
    },
    {
        imageUrl:'/images/Services/canteen.jpg',
        title:'Canteen',
        desc:'We cater to your food requirements as well. We provide breakfast, lunch & dinner services in our canteen.'

    },
    {
        imageUrl:'/images/Services/waterPurifier.jpg',
        title:'Purified Water',
        desc:'We also provide purified water 24*7 keeping the good health in mind.'
    },
    {
        imageUrl:'/images/Services/houseKeeping.jpg',
        title:'HouseKeeping',
        desc:'We also provide housekeeping services like laundry, dish cleaning and room cleaning based on requirements.'
    },
    {
        imageUrl:'/images/Services/watchman.jpg',
        title:'Watchmen',
        desc:'Depending on the purpose of keeping a close watch on the visitors and passersby we provide efficient watchmen.'

    },
    {
        imageUrl:'/images/Services/metro.jpg',
        title:'Nearby Transportation',
        desc:'Just 100m walking distance from metro station.'

    },
    {
        imageUrl:'/images/Services/waterPurifier.jpg',
        title:'Power Backup',
        desc:''

    },
    {
        imageUrl:'/images/Services/houseKeeping.jpg',
        title:'Parking Facility',
        desc:''

    },
    {
        imageUrl:'/images/Services/furnishedRoom.jpg',
        title:'Refrigerator',
        desc:''

    },
    {
        imageUrl:'/images/Services/metro.jpg',
        title:'Geyser',
        desc:''

    },
    {
        imageUrl:'/images/Services/canteen.jpg',
        title:'Air Conditioning',
        desc:''

    },
    
 ];