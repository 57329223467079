import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Rooms } from '../Home/Home';

function RoomPage() {
  return (
    <>
    <Helmet>
    <title>Rooms - Suresh PG</title>
    <meta name="description" content="Affordable PG accommodation near South Extension, Delhi with fully furnished AC rooms. Amenities include housekeeping, Wi-Fi, and delicious meals. Suitable for boys, girls, students, and working professionals." />
    
    <meta property="og:title" content="PG near South Extension, Delhi with Food | For Boys, Girls" />
    <meta property="og:description" content="Affordable PG accommodation near South Extension, Delhi with fully furnished AC rooms. Amenities include housekeeping, Wi-Fi, and delicious meals. Suitable for boys, girls, students, and working professionals." />
    <meta property="og:image" content="https://sureshhostel.com/images/Logo.png" />
    <meta property="og:url" content="https://sureshhostel.com/pg-rooms-in-south-extension" />
    <meta property="og:type" content="website" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Rooms - Suresh PG" />
    <meta name="twitter:description" content="Affordable PG accommodation near South Extension, Delhi with fully furnished AC rooms. Amenities include housekeeping, Wi-Fi, and delicious meals. Suitable for boys, girls, students, and working professionals." />
    <meta name="twitter:image" content="https://sureshhostel.com/images/Logo.png" />

    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="keywords" content="PG In Delhi, Paying Guest In Delhi, pg for girls in delhi, pg rent in delhi, pg rooms in delhi, pg hostel in delhi, pg in delhi for boys, pg for couples in delhi, unisex pg in delhi" data-react-helmet="true" />
    </Helmet>

    <Rooms/>
    </>
  )
}

export default RoomPage;