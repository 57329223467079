export const servicePointer =[
    "Fully Furnished Rooms: Includes bed, study table, chair, wardrobe, etc",
    "Shared or Private Rooms: Options for single, double, or dormitory-style rooms",
    "Balcony / Terrace Access: Outdoor space for relaxation.",
    "Daily Housekeeping: Regular cleaning of rooms and common areas.",
    "Laundry Services: On-site laundry facilities or laundry services.",
    "Wi-Fi Connectivity: High-speed internet access throughout the property.",
    "24/7 Security: Security personnel, CCTV surveillance, and secure access.",
    "Common Kitchen: Shared kitchen facilities for self-cooking.",
    "First Aid Facilities: Basic medical supplies and first aid kits.",
    "Computer Stations: Shared computers or workstations for resident use.",
    "Just 100m walking distance from metro station",
]