import React from 'react'
import AboutUs from './AboutUs'
import { Helmet } from 'react-helmet-async'

function AboutUsPage() {
  return (
    <>
    <Helmet>
    <title>About Us - Suresh Hostel/PG</title>
    
    <meta name="description" content="Learn more about Suresh Hostel/PG, a trusted accommodation provider in South Extension, Delhi, offering fully furnished rooms with modern amenities for students and working professionals." />
    <meta name="author" content="Suresh Hostel/PG" />
    <meta name="keywords" content="About Suresh Hostel, About PG in Delhi, Hostel services in Delhi, PG accommodation in South Extension" />
    
    <meta property="og:title" content="About Us - Suresh Hostel/PG" />
    <meta property="og:description" content="Discover Suresh Hostel/PG, a reliable PG service in South Extension, Delhi, providing comfortable and fully furnished rooms with top-notch amenities for students and professionals." />
    <meta property="og:image" content="https://sureshhostel.com/images/Logo.png" />
    <meta property="og:url" content="https://sureshhostel.com/about-us" />
    <meta property="og:type" content="website" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="About Us - Suresh Hostel/PG" />
    <meta name="twitter:description" content="Explore Suresh Hostel/PG, a leading PG provider in South Extension, Delhi, offering fully furnished rooms and essential amenities for a comfortable stay." />
    <meta name="twitter:image" content="https://sureshhostel.com/images/Logo.png" />

    <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>

    <AboutUs/>
    </>
  )
}

export default AboutUsPage