import React from 'react'
import { Helmet } from 'react-helmet-async';
import styles from '../Home/Home.module.css';
import { FaPhoneAlt, FaTelegram } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';
import { MdAccessTimeFilled } from 'react-icons/md';
function Contact() {
  return (
    <div className={styles.Contactus}>
        
        
        <div  className={styles.addressContainer}>
            
            <div className={styles.address}>

                <h2>Our Mailing Address</h2>
                <div className={styles.addComponent}>
                <FaTelegram size={'45px'}/>
                <p>
                C-54, Ground floor, near Bengali Sweets, South Extension I, Block E, New Delhi, Delhi 110049
                </p>
                </div>
                <div className={styles.addComponent}>
                <IoMdMail size={'30px'}/>
                <p>info@sureshhostel.com</p>
                </div>
                <div className={styles.addComponent}>
                <FaPhoneAlt size={'30px'}/>
                <p>+91-8882141008,+91-9599153466</p>
                </div>
                <div className={styles.addComponent}>
                <MdAccessTimeFilled size={'30px'}/>
                <p>Office Hours: 9 AM to 8 PM (all 7 days)</p>
                </div>
            </div>
            <div className={styles.googleMap}>
            {/* <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.980705794024!2d77.21835127495444!3d28.570342086903068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38655b1600d%3A0xdd92873c6abc8438!2sSuresh%20PG%20girls%20%26%20boys!5e0!3m2!1sen!2sin!4v1724920028115!5m2!1sen!2sin"
                width="600" height="450" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.980705794024!2d77.21835127495444!3d28.570342086903068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38655b1600d%3A0xdd92873c6abc8438!2sSuresh%20PG%20girls%20%26%20boys!5e0!3m2!1sen!2sin!4v1724920028115!5m2!1sen!2sin"
                width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            </div>
        </div>
    </div>
  )
}

export default Contact