export const girlsRoomData =[
    {
        title:'Single Sharing Rooms',
        images:[
            '/images/Rooms/SingleBed/g1.jpg',
            '/images/Rooms/SingleBed/g2.jpg',
        ]
    },
    
    {
        title:'Double Sharing Rooms',
        images:[
            '/images/Rooms/DoubleBed/g1.jpg',
            '/images/Rooms/DoubleBed/g2.jpg',
        ]
    },
    {
        title:'Three Sharing Rooms',
        images:[
            '/images/Rooms/ThreeBed/g1.jpg',
            '/images/Rooms/ThreeBed/g2.jpg',
            '/images/Rooms/ThreeBed/g3.jpg',
        ]
    },
    {
        title:'Four Sharing Rooms',
        images:[
            '/images/Rooms/FourBed/g1.jpg',
            '/images/Rooms/FourBed/g2.jpg',
        ]
    }
    
    
];