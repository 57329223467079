import React from 'react'
import Gallery from './Gallery'
import { Helmet } from 'react-helmet-async'

function GalleryPage() {
  return (
    <>
        <Helmet>
            <title>Gallery - Suresh Hostel/PG</title>
        </Helmet>
        <Gallery/>
    </>
  )
}

export default GalleryPage